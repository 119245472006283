import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from 'components/layout'
import Head from 'components/head'

// import Box from 'components/box';

import 'style/cases.scss'

class Cases extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      allIn: true,
    }
  }
  render() {
    const { data } = this.props
    const items = data.allItems.edges

    console.log({ items })

    return (
      <Layout locale="nl" className="main-container case">
        <Head
          pageTitle="Klanten aan het woord met hun eigen verhaal over het verhuren van
          accommodaties"
          locale="nl"
        />
        <section className="container case-container">
          <h1 className="bukazu-h1-header">
            Klanten aan het woord met hun eigen verhaal over het verhuren van
            accommodaties
          </h1>
          <div className="case-holder">
            {items.map(item => {
              return CaseItem(item.node)
            })}
          </div>
        </section>
      </Layout>
    )
  }
}

const CaseItem = item => {
  const fields = JSON.parse(item.field)
  // console.log({ fields })

  return (
    <article key={item.id} className="case-article">
      <div className="case-article-image">
        <img
          src={
            '//cdn.burobork.nl/' +
            fields.fields['Feature Afbeelding'][0].reference
          }
        />
      </div>
      <div className="case-article-content">
        <h2 className="bukazu-h2">{fields.fields['Long title']}</h2>
        <Link to={`/klantcases/${fields.url}`}>
          Bekijk case{' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            viewBox="0 0 100 100"
            x="0px"
            y="0px"
          >
            <title>Forward</title>
            <path d="M97.64,44.1,64.72,11.18a8.06,8.06,0,1,0-11.4,11.39L72.78,42H8.06a8.06,8.06,0,0,0,0,16.12H72.6L53.32,77.43a8.06,8.06,0,0,0,11.4,11.39L97.64,55.9A8,8,0,0,0,100,50.2a1.27,1.27,0,0,0,0-.2,1.41,1.41,0,0,0,0-.2A8.07,8.07,0,0,0,97.64,44.1Z" />
          </svg>
        </Link>
      </div>
    </article>
  )
}

Cases.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query BuroBorkCases {
    allItems(
      filter: { group: { eq: "Cases" } }
      sort: { fields: id, order: DESC }
    ) {
      edges {
        node {
          id
          title
          field
        }
      }
    }
  }
`

export default Cases
